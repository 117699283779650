exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-bulletin-safety-tsx": () => import("./../../../src/templates/bulletin-safety.tsx" /* webpackChunkName: "component---src-templates-bulletin-safety-tsx" */),
  "component---src-templates-dynamic-page-tsx": () => import("./../../../src/templates/dynamic-page.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-tsx" */),
  "component---src-templates-local-news-heide-tsx": () => import("./../../../src/templates/local-news-heide.tsx" /* webpackChunkName: "component---src-templates-local-news-heide-tsx" */),
  "component---src-templates-local-news-montreal-tsx": () => import("./../../../src/templates/local-news-montreal.tsx" /* webpackChunkName: "component---src-templates-local-news-montreal-tsx" */),
  "component---src-templates-local-news-voltschnack-tsx": () => import("./../../../src/templates/local-news-voltschnack.tsx" /* webpackChunkName: "component---src-templates-local-news-voltschnack-tsx" */),
  "component---src-templates-redirect-tsx": () => import("./../../../src/templates/redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-tsx" */),
  "component---src-templates-tag-overview-tsx": () => import("./../../../src/templates/tag-overview.tsx" /* webpackChunkName: "component---src-templates-tag-overview-tsx" */)
}

