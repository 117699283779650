import type { GatsbyBrowser } from "gatsby"

const initGTM = () => {
  if (window.isGTMLoaded) {
    return false
  }

  window.isGTMLoaded = true

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_CONTAINER}');`

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag(x: string, y: string) {
      window.dataLayer.push({ x, y })
    }
    gtag("js", new Date().toString())
    gtag("config", `${process.env.GATSBY_GTM_CONTAINER}`)
  }

  document.head.appendChild(script)
}

const loadGTM = (event: Event) => {
  initGTM()
  if (event && event.currentTarget) {
    event.currentTarget.removeEventListener(event.type, loadGTM)
  }
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  // Load GTM after waiting a bit
  document.onreadystatechange = () => {
    if (document.readyState !== "loading") {
      setTimeout(() => {
        initGTM()
      }, 3500)
    }
  }

  // Listen for user events before loading GTM
  document.addEventListener("scroll", loadGTM)
  document.addEventListener("mousemove", loadGTM)
  document.addEventListener("touchstart", loadGTM)
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "gatsby-route-change",
    path: location.pathname,
  })
}
